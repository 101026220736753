<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';
import GDropdown from '../../elements/Dropdown.vue';
import GTooltip from '../../base/GTooltip.vue';
import GInput from '../../base/GInput.vue';
import PageStatus from './PageStatus.vue';
import { computed, nextTick, ref } from 'vue';
import GButton from '../../base/GButton.vue';

type Props = {
  pageType?: {
    name?: string;
    icon?: string;
  };
  pageName?: string;
  isEditThemeSection?: boolean;
  publishStatus?: boolean;
  saved?: boolean;
  lastAction?: string;
  updatedAt?: string;
};
const MAX_LENGTH_THEME_SECTION_NAME = 25;

const props = withDefaults(defineProps<Props>(), {
  pageName: 'Untitled',
  isEditThemeSection: false,
  updatedAt: new Date().toString(),
});
const updatedTime = ref(props.updatedAt);

const emit = defineEmits<{
  (e: 'back'): void;
  (e: 'rename', name: string): void;
}>();

const currentName = ref<string>(props.pageName);
const inputRef = ref();

const onBack = () => {
  if (props.isEditThemeSection) return;
  emit('back');
};

const onChangePageName = (onClose: Function) => {
  onClose();
  handleChangePageName(currentName.value);
};

const handleChangePageName = (value: string) => {
  emit('rename', value);
};

const handleClose = (close: Function) => {
  close();
  currentName.value = props.pageName;
};

const onChangeCurrentName = (value: string) => {
  currentName.value = value;
};
const statusPage = computed(() => {
  if (!props.publishStatus) {
    return 'draft';
  } else if (props.lastAction === 'pageSave') return 'needRepublish';
  return 'published';
});

const onOpen = () => {
  nextTick(() => {
    inputRef.value.focus();
  });
};
watch(statusPage, () => {
  updatedTime.value = new Date().toString();
});
</script>

<template>
  <div class="rounded-medium flex h-40 flex-1 items-center justify-center px-16 after:flex-row">
    <template v-if="pageType">
      <g-tooltip placement="bottom" :margin-top="8" class="flex py-4" :disabled="isEditThemeSection">
        <div
          class="rounded-medium text-dark-disabled flex h-32 cursor-pointer select-none items-center whitespace-nowrap transition-all duration-200"
          :class="{ '!cursor-default': isEditThemeSection, 'hover:text-dark-low': !isEditThemeSection }"
          @click="onBack">
          <span>
            <g-base-icon :name="(pageType.icon as any)" width="20" height="20"></g-base-icon>
          </span>
          <span class="text-14 font-regular max-ls:hidden px-4 text-center">{{ pageType.name }}</span>
          <div class="flex items-center pl-4">
            <span class="font-regular inline-flex text-[14px]"> / </span>
          </div>
        </div>
        <template #content>
          <span class="text-12 flex items-center">Back to {{ pageType?.name?.toLowerCase() }} list</span>
        </template>
      </g-tooltip>
    </template>
    <div class="static ml-8 flex items-center">
      <g-dropdown
        class-popover="rounded-xl !bg-dark-500 mt-[16px]"
        trigger="click"
        placement=""
        @open="onOpen"
        @outside-click="currentName = props.pageName">
        <template #default="{ isOpening }">
          <div class="rounded-medium min-[1200px] flex items-center">
            <g-tooltip :force-close="isOpening" placement="bottom" :disabled="isOpening">
              <span
                class="text-14 text-dark-low hover:text-light-hover line-clamp-1 cursor-pointer overflow-hidden text-ellipsis break-all text-start font-medium">
                {{ pageName }}
              </span>
              <template #content>
                <span class="text-12 flex items-center">Rename page title</span>
              </template>
            </g-tooltip>
          </div>
        </template>

        <template #content="{ close }">
          <div class="w-[418px] px-4 sm:px-0">
            <div class="flex h-[52px] flex-row items-center justify-between overflow-hidden">
              <h3 class="text-16 text-light-hover pl-16 font-semibold leading-6">
                {{ isEditThemeSection ? 'Theme Section Title' : 'Rename page title' }}
              </h3>
              <span
                class="text-dark-low group flex aspect-square h-[52px] cursor-pointer items-center justify-center transition-colors duration-200"
                @click="handleClose(close)">
                <div
                  class="group-hover:bg-dark-hover text-light-hover flex h-[32px] w-[32px] items-center justify-center rounded-xl">
                  <g-base-icon name="close-tooltip" view-box="0 0 16 16" width="16" height="16"></g-base-icon>
                </div>
              </span>
            </div>
            <div class="px-16 py-8">
              <g-input
                ref="inputRef"
                class="bg-light-high !text-14 !text-light-hover hover:bg-dark-hover h-[40px] rounded-xl outline-none"
                input-style="secondary"
                size="medium"
                type="text"
                :max-length="isEditThemeSection ? MAX_LENGTH_THEME_SECTION_NAME : undefined"
                :value="currentName"
                @on-change="onChangeCurrentName"></g-input>
            </div>
            <div class="flex h-[64px] items-center justify-end gap-8 px-16">
              <GButton type="secondary" button-classes="!text-light-hover" @click="handleClose(close)">
                Cancel
              </GButton>
              <GButton button-classes="!text-light-hover" @click="onChangePageName(close)"> Update </GButton>
            </div>
          </div>
        </template>
      </g-dropdown>

      <PageStatus v-if="!isEditThemeSection" :status="statusPage" :last-updated-at="updatedTime"></PageStatus>
    </div>
    <div>
      <slot name="more"></slot>
    </div>
  </div>
</template>
