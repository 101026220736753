<template>
  <path
    d="M10 5.625C10.3452 5.625 10.625 5.90482 10.625 6.25V9.375H13.75C14.0952 9.375 14.375 9.65482 14.375 10C14.375 10.3452 14.0952 10.625 13.75 10.625H10.625V13.75C10.625 14.0952 10.3452 14.375 10 14.375C9.65482 14.375 9.375 14.0952 9.375 13.75V10.625H6.25C5.90482 10.625 5.625 10.3452 5.625 10C5.625 9.65482 5.90482 9.375 6.25 9.375H9.375V6.25C9.375 5.90482 9.65482 5.625 10 5.625Z"
    fill="currentColor" />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.81282 3.81282C7.22991 0.395728 12.7701 0.395728 16.1872 3.81282C19.6043 7.22991 19.6043 12.7701 16.1872 16.1872C12.7701 19.6043 7.22991 19.6043 3.81282 16.1872C0.395728 12.7701 0.395728 7.22991 3.81282 3.81282ZM4.6967 4.6967C7.62563 1.76777 12.3744 1.76777 15.3033 4.6967C18.2322 7.62563 18.2322 12.3744 15.3033 15.3033C12.3744 18.2322 7.62563 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62563 4.6967 4.6967Z"
    fill="currentColor" />
</template>
