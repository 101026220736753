<script lang="ts" setup>
import { ref } from 'vue';
import { arrowPlacement, arrow, useFloating } from '../composables/useFloating';
import type { Placement, Strategy } from '@floating-ui/core';
import { offset, shift, flip } from '@floating-ui/core';
import { useOutsideClick } from '../composables/useOutsideClick';

type Props = {
  ignoreOutsideClass?: string;
  closeable?: boolean;
  wrapperClass?: string;
  wrapContentClass?: string;
  buttonClass?: string;
  arrowClass?: string;
  overlayContainer?: string;
  overlay?: boolean;
  overlayClass?: string;
  strategy?: Strategy;
  disabled?: boolean;
  hasArrow?: boolean;
  placement?: Placement;
  stopPropagation?: boolean;
  preventDefault?: boolean;
  cls?: string;
  paddingRight?: number;
  noShadowBox?: boolean;
  isHiddenDom?: boolean;
  isOpenModal?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  overlayContainer: 'body',
  overlayClass: 'bg-dark-500/80',
  strategy: 'fixed',
  hasArrow: true,
  autoPlacement: true,
  paddingRight: 0,
  noShadowBox: false,
  isHiddenDom: false,
  isOpenModal: false,
});

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'open'): void;
}>();

const open = ref(false);
const arrowEl = ref<HTMLElement>();

function close() {
  open.value = false;
}

function onOpen() {
  if (!open.value) {
    open.value = true;
    emit('open');
  } else if (props.closeable && open.value) {
    close();
    emit('close');
  }
}

const { x, y, reference, floating, strategy, middlewareData } = useFloating({
  strategy: props.strategy,
  placement: props.placement,
  middleware: [
    flip(),
    shift(),
    offset(8),
    arrow({
      element: arrowEl,
    }),
    arrowPlacement(),
  ],
});

function onClickOpen(e: MouseEvent) {
  if (props.stopPropagation) e.stopPropagation();
  if (props.preventDefault) e.preventDefault();
  onOpen();
}

const handleClickOutSide = () => {
  if (props.closeable && open.value) {
    close();
    return;
  }
};

useOutsideClick(floating, handleClickOutSide, {
  containSelectors: props.ignoreOutsideClass ? [`.${props.ignoreOutsideClass}`] : [],
  detectIframe: true,
});
</script>

<template>
  <div ref="reference" :class="buttonClass" class="relative inline-flex">
    <div :class="wrapContentClass" @click="onClickOpen">
      <slot v-bind="{ open, close }"></slot>
    </div>
    <Teleport :to="overlayContainer">
      <Transition v-if="overlay" name="overlay">
        <div v-if="open" :class="overlayClass" class="absolute inset-0 z-30"></div>
      </Transition>
      <Transition name="popover">
        <div
          v-if="isHiddenDom ? open : true"
          v-show="!isHiddenDom ? open : true"
          ref="floating"
          :style="{
            position: strategy,
            top: y ? `${y}px` : '',
            left: x ? `${x - paddingRight}px` : `0px`,
          }"
          class="z-[999]"
          :class="[{ 'shadow-4dp': !noShadowBox }, wrapperClass, isOpenModal && 'hidden']">
          <div class="rounded-12 relative p-8 text-white" :class="[cls, { 'bg-dark-400': hasArrow }]">
            <div
              v-if="hasArrow"
              ref="arrowEl"
              class="border-r-dark-400 absolute border-y-4 border-r-4 border-l-0 border-solid border-y-transparent"
              :style="{
                top: middlewareData.arrow?.y != null ? `${middlewareData.arrow.y}px` : '',
                left: middlewareData.arrow?.x != null ? `${middlewareData.arrow.x}px` : '',
              }"
              :class="[
                arrowClass,
                {
                  '-right-8 rotate-180': middlewareData.arrowPlacement?.placement?.startsWith('left'),
                  '-left-8': middlewareData.arrowPlacement?.placement?.startsWith('right'),
                  'top-[-6px] rotate-90': middlewareData.arrowPlacement?.placement?.startsWith('bottom'),
                  'bottom-[-6px] -rotate-90': middlewareData.arrowPlacement?.placement?.startsWith('top'),
                },
              ]"></div>
            <div>
              <slot name="content" v-bind="{ open, close }"></slot>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<style scoped>
.popover-enter-active,
.popover-leave-active {
  transition: opacity 0.2s ease;
  opacity: 1;
  /* top: 100%; */
}

.popover-enter-from,
.popover-leave-to {
  opacity: 0;
}

.overlay-enter-active,
.overlay-leave-active {
  transition: opacity 0.3s ease;
}

.overlay-enter-from,
.overlay-leave-to {
  opacity: 0;
}
</style>
