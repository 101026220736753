<script lang="ts" setup>
import type { Thumbnail } from '../types/image';
import { onUnmounted, ref } from 'vue';
import GLoadingPlaceholder from '../base/GLoadingPlaceholder.vue';
import GButton from '../base/GButton.vue';
import GImage from '../base/GImage.vue';
let imageLoadingTimeout: NodeJS.Timeout | null;
const VELOCITY = 0.3;

defineProps<{
  isLoading?: boolean;
  isPreview?: boolean;
  section: {
    name: string;
    thumbnail: Thumbnail;
  };
  isSelectLoading?: boolean;
  isPreviewLoading?: boolean;
  thumbnailPreview?: string;
}>();

// Emit
const emit = defineEmits<{
  (e: 'select'): void;
  (e: 'preview'): void;
}>();

const thumbnailLoading = ref<boolean>(true);

const handleSelect = () => {
  emit('select');
};
const handlePreview = () => {
  emit('preview');
};
const onThumbnailLoaded = () => {
  thumbnailLoading.value = false;
};

onUnmounted(() => {
  if (imageLoadingTimeout) {
    clearTimeout(imageLoadingTimeout);
  }
});
</script>
<template>
  <div
    class="rounded-medium z-5 bg-light-100 group relative !my-0 h-auto w-full overflow-hidden transition-all delay-[0.25s]">
    <g-image
      :input-classes="`rounded-t-medium pt-[calc(100%+33px)] xl:pt-[calc(100%+42px)] xxl:pt-[calc(100%+82.5px)] w-full cursor-pointer overflow-hidden ${
        isPreview ? 'cursor-pointer' : ''
      }`"
      cls-image="absolute top-0 left-0 w-full"
      width="100%"
      :src="section.thumbnail.src"
      :origin-width="0"
      :origin-height="0"
      :velocity="VELOCITY"
      @click.stop="handlePreview"
      @on-loaded="onThumbnailLoaded" />

    <div v-if="isLoading || thumbnailLoading" class="absolute inset-0 flex flex-col items-center justify-center">
      <g-loading-placeholder width="100%" height="100%" />
      <div class="bg-light-200 flex h-[48px] w-full items-center justify-between overflow-hidden border-t py-8 px-12">
        <div class="flex h-full max-w-full items-center group-hover:hidden">
          <div class="text-light-high text-14 inline-block max-w-full truncate align-middle font-medium">
            {{ section.name }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isLoading"
      class="bg-light-200 group bottom-[-52px] left-0 flex h-[52px] w-full items-center gap-8 overflow-hidden border-t py-8 px-12 transition-all duration-200 group-hover:bottom-0">
      <div class="flex h-full max-w-full items-center group-hover:hidden">
        <div class="text-light-high text-14 inline-block max-w-full truncate align-middle font-medium leading-6">
          {{ section.name }}
        </div>
      </div>

      <g-button
        class="text-12 hidden h-[36px] w-[calc(50%_-_4px)] group-hover:flex"
        button-classes="justify-center w-full"
        type="primary"
        size="medium"
        :loading="isSelectLoading"
        @click.stop="handleSelect"
        >Add to page</g-button
      >
      <g-button
        class="text-12 hidden h-[36px] w-[calc(50%_-_4px)] group-hover:flex"
        :loading="isPreviewLoading"
        type="grey"
        size="medium"
        :only-icon="true"
        :disable="!isPreview"
        @click.stop="handlePreview">
        Preview
      </g-button>
    </div>
  </div>
</template>
