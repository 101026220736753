<script lang="ts" setup generic="T extends DragItemData">
import { onMounted, ref } from 'vue';
import Sortable from 'sortablejs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { DragItemData } from './types';

type Props = {
  items: T[];
  dragItemClass?: string;
};
const props = defineProps<Props>();
const emit = defineEmits<{ (e: 're-order', items: T[], from?: number, to?: number): void }>();

function moveByIndex<P>(input: P[], from: number, to: number): P[] {
  if (from < 0 || to < 0) return input;
  const newArr = JSON.parse(JSON.stringify(input));
  const elm = newArr.splice(from, 1)[0];
  if (elm !== undefined) {
    newArr.splice(to, 0, elm);
  }
  return newArr as P[];
}
const dragging = ref(false);
const el = ref<HTMLElement | null>(null);

onMounted(() => {
  if (el.value) {
    new Sortable(el.value, {
      animation: 150,
      sort: true,
      direction: 'vertical',
      draggable: '.handle',
      filter: '.non-draggable',
      ghostClass: 'sortable-ghost',
      forceFallback: true,
      fallbackOnBody: true,
      onStart: () => {
        dragging.value = true;
      },
      onEnd: () => {
        dragging.value = false;
      },
      onMove(e) {
        return e.related.className.indexOf('non-draggable') === -1;
      },
      onUpdate: (evt) => {
        if (typeof evt.newIndex !== 'number' || typeof evt.oldIndex !== 'number') return;
        const ii = moveByIndex(props.items, evt.oldIndex, evt.newIndex);
        emit('re-order', ii, evt.oldIndex, evt.newIndex);
      },
    });
  }
});
</script>

<template>
  <div
    ref="el"
    class="group/sortable w-[248px]"
    :class="{
      dragging: dragging,
    }">
    <div v-for="(item, index) in items" :key="item.id" class="handle group/sortable-item" :data-id="item.id">
      <div :class="dragItemClass">
        <slot :item="item" :index="index"></slot>
      </div>
    </div>
  </div>
</template>
