<template>
  <g clip-path="url(#clip0_103_57563)">
    <path d="M21 18.5C21 20.0913 20.3679 21.6174 19.2426 22.7426C18.1174 23.8679 16.5913 24.5 15 24.5C13.4087 24.5 11.8826 23.8679 10.7574 22.7426C9.63214 21.6174 9 20.0913 9 18.5C9 16.9087 9.63214 15.3826 10.7574 14.2574C11.8826 13.1321 13.4087 12.5 15 12.5C16.5913 12.5 18.1174 13.1321 19.2426 14.2574C20.3679 15.3826 21 16.9087 21 18.5ZM18.0225 16.2275C17.9689 16.1741 17.9051 16.1321 17.8349 16.1039C17.7648 16.0757 17.6896 16.062 17.614 16.0636C17.5384 16.0651 17.4638 16.0819 17.3949 16.1129C17.3259 16.1439 17.2639 16.1885 17.2125 16.244L14.6077 19.5627L13.038 17.9922C12.9314 17.8929 12.7903 17.8388 12.6446 17.8414C12.4989 17.8439 12.3598 17.903 12.2568 18.006C12.1537 18.1091 12.0947 18.2481 12.0921 18.3939C12.0895 18.5396 12.1436 18.6806 12.243 18.7873L14.2275 20.7725C14.281 20.8259 14.3446 20.8679 14.4147 20.8961C14.4848 20.9244 14.5598 20.9382 14.6353 20.9368C14.7108 20.9354 14.7853 20.9188 14.8543 20.888C14.9232 20.8572 14.9853 20.8128 15.0367 20.7575L18.0308 17.015C18.1328 16.9089 18.1892 16.767 18.1878 16.6197C18.1864 16.4725 18.1273 16.3317 18.0233 16.2275H18.0225Z" fill="#AAAAAA"/>
  </g>
  <rect x="27" y="12.5" width="30" height="3.5" rx="1.75" fill="#474747"/>
  <rect x="27" y="19" width="30" height="3.5" rx="1.75" fill="#474747"/>
  <rect x="27" y="25.5" width="30" height="3.5" rx="1.75" fill="#474747"/>
  <rect x="27" y="32" width="18" height="3.5" rx="1.75" fill="#474747"/>
  <defs>
    <clipPath id="clip0_103_57563">
      <rect width="12" height="12" fill="white" transform="translate(9 12.5)"/>
    </clipPath>
  </defs>
</template>
