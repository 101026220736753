<script lang="ts" setup>
import { computed, ref } from 'vue';
import { GChip } from '@gem/uikit';
import GLoadingPlaceholder from '../base/GLoadingPlaceholder.vue';
import GButton from '../base/GButton.vue';
import GBaseIcon from '../base/GBaseIcon.vue';
import GImage from '../base/GImage.vue';
import GThumbnailLoading from './loading/GThumbnailLoading.vue';

const VELOCITY = 0.325;
const OFFSET_DISTANCE = 0;

const props = defineProps<{
  isOpenMoreSetting?: boolean;
  isLoading?: boolean;
  section: {
    name: string;
    lastedModify?: string;
    isRepublish?: boolean;
    thumbnail: {
      src: string;
    };
  };
  isSelectLoading?: boolean;
  isPreviewLoading?: boolean;
  isOpenModal?: boolean;
}>();

const emit = defineEmits<{
  (e: 'select'): void;
  (e: 'edit'): void;
}>();

const isShowNumberAddedToPage = ref(false);
const heightCalculator = ref({
  xl: 315,
  lg: 324,
  md: 324,
});
const isShowMoreSetting = computed(() => props.isOpenMoreSetting);

const handleSelect = () => {
  emit('select');
};
const handleEdit = () => {
  emit('edit');
};
</script>
<template>
  <div
    class="rounded-medium bg-light-100 z-5 group relative flex h-auto w-full flex-col overflow-hidden transition-all delay-[0.25s]">
    <div v-if="section?.isRepublish" class="z-5 absolute top-8 right-8 flex">
      <GChip type="warning" mode="light">Need to republish</GChip>
    </div>
    <GThumbnailLoading v-if="!section.thumbnail.src" />
    <GImage
      v-else
      input-classes="w-full cursor-pointer min-h-[120px] z-[1] shrink grow max-h-[324px] xl:max-h-[315px] "
      width="100%"
      :src="section.thumbnail.src"
      :height-calculator="heightCalculator"
      :velocity="VELOCITY"
      :offset-distance="OFFSET_DISTANCE" />
    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center bg-white">
      <GLoadingPlaceholder width="100%" height="100%"></GLoadingPlaceholder>
    </div>
    <div
      v-if="!isLoading"
      class="bg-light-100 border-light-400 z-[2] flex h-[74px] w-full items-center overflow-hidden border-t px-16 transition-all duration-200">
      <div
        class="flex w-full flex-col justify-center group-hover:hidden"
        :class="{
          '!hidden': isShowMoreSetting && !isOpenModal,
        }">
        <p class="text-light-high text-14 mb-4 w-full truncate align-middle font-medium leading-6">
          {{ section.name }}
        </p>
        <div class="inline-flex w-full items-center">
          <p
            class="text-light-disabled text-12 font-regular truncate align-middle leading-[14px]"
            v-html="section?.lastedModify"></p>
          <GBaseIcon v-if="isShowNumberAddedToPage" width="12px" class="text-dark-low mx-4" name="small-dot" />
          <p
            v-if="isShowNumberAddedToPage"
            class="text-light-disabled text-12 font-regular truncate align-middle leading-[14px]">
            Added to 10 pages
          </p>
        </div>
      </div>
      <div
        class="hidden w-full group-hover:flex"
        :class="{
          '!flex': isShowMoreSetting && !isOpenModal,
        }">
        <GButton
          type="primary"
          class="text-light-200V2 !text-12 h-36 flex-1 leading-5"
          size="large"
          :loading="isSelectLoading"
          @click.stop="handleSelect"
          >Add to page</GButton
        >
        <GButton type="grey" class="!text-12 ml-8 h-36 flex-1 leading-5" size="large" @click.stop="handleEdit"
          >Edit</GButton
        >
        <slot name="more-setting"></slot>
      </div>
    </div>
  </div>
</template>
