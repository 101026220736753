<template>
  <rect x="0.5" y="0.5" width="65" height="47" rx="2.5" fill="#212121"/>
  <g clip-path="url(#clip0_103_57555)">
    <path d="M21.5 24C21.5 25.5913 20.8679 27.1174 19.7426 28.2426C18.6174 29.3679 17.0913 30 15.5 30C13.9087 30 12.3826 29.3679 11.2574 28.2426C10.1321 27.1174 9.5 25.5913 9.5 24C9.5 22.4087 10.1321 20.8826 11.2574 19.7574C12.3826 18.6321 13.9087 18 15.5 18C17.0913 18 18.6174 18.6321 19.7426 19.7574C20.8679 20.8826 21.5 22.4087 21.5 24ZM18.5225 21.7275C18.4689 21.6741 18.4051 21.6321 18.3349 21.6039C18.2648 21.5757 18.1896 21.562 18.114 21.5636C18.0384 21.5651 17.9638 21.5819 17.8949 21.6129C17.8259 21.6439 17.7639 21.6885 17.7125 21.744L15.1077 25.0627L13.538 23.4922C13.4314 23.3929 13.2903 23.3388 13.1446 23.3414C12.9989 23.3439 12.8598 23.403 12.7568 23.506C12.6537 23.6091 12.5947 23.7481 12.5921 23.8939C12.5895 24.0396 12.6436 24.1806 12.743 24.2873L14.7275 26.2725C14.781 26.3259 14.8446 26.3679 14.9147 26.3961C14.9848 26.4244 15.0598 26.4382 15.1353 26.4368C15.2108 26.4354 15.2853 26.4188 15.3543 26.388C15.4232 26.3572 15.4853 26.3128 15.5367 26.2575L18.5308 22.515C18.6328 22.4089 18.6892 22.267 18.6878 22.1197C18.6864 21.9725 18.6273 21.8317 18.5233 21.7275H18.5225Z" fill="#AAAAAA"/>
  </g>
  <rect x="26.5" y="12.5" width="30" height="3.5" rx="1.75" fill="#474747"/>
  <rect x="26.5" y="19" width="30" height="3.5" rx="1.75" fill="#474747"/>
  <rect x="26.5" y="25.5" width="30" height="3.5" rx="1.75" fill="#474747"/>
  <rect x="26.5" y="32" width="18" height="3.5" rx="1.75" fill="#474747"/>
  <rect x="0.5" y="0.5" width="65" height="47" rx="2.5" />
  <defs>
    <clipPath id="clip0_103_57555">
      <rect width="12" height="12" fill="white" transform="translate(9.5 18)"/>
    </clipPath>
  </defs>
</template>
