<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';
import GTooltip from '../../base/GTooltip.vue';
import WarningAlert from './WarningAlert.vue';
import { useField } from 'vee-validate';
import { computed } from 'vue';

type Props = {
  url?: string;
  validateHandle?: boolean;
  isHomePage?: boolean;
  pageType?: string;
  shopDomain?: string;
  errors?: {
    title?: string;
    handle?: string;
    description?: string;
  };
};

const props = withDefaults(defineProps<Props>(), {
  url: 'https://demo.gempages.link',
  validateHandle: true,
});

const { value: title } = useField<string>('title');
const { value: handle, handleChange: onChangeHandle } = useField<string>('handle');
const { value: description } = useField<string>('description');

const handleChangeHandle = (e: Event) => {
  const formatHandle = (e.target as HTMLInputElement)?.value?.replace(/[^-_A-Za-z0-9]/g, '-') || '';

  onChangeHandle(formatHandle, true);
};

const wrapHandle = computed(() => {
  if (props.pageType === 'GP_ARTICLE') {
    if (parseHandle.value.handleSplit.length > 1) {
      return handle.value.replace(`${parseHandle.value.patchName}/`, '');
    }

    return handle.value;
  }

  return handle.value;
});

const wrapShopDomain = computed(() => {
  if (props.pageType === 'GP_STATIC') {
    return `${props.shopDomain}/pages/`;
  }

  if (props.pageType === 'GP_ARTICLE') {
    return articleDomain.value;
  }

  return props.shopDomain;
});

const articleDomain = computed(() => {
  if (parseHandle.value.handleSplit.length > 1) {
    return `${props.shopDomain}/blogs/${parseHandle.value.patchName}/`;
  }

  return `${props.shopDomain}/blogs/news/`;
});

const parseHandle = computed(() => {
  const handleSplit = handle.value.split('/');
  const patchName = handle.value.split('/')[0];

  return { handleSplit, patchName };
});

const pageUrl = computed(() => {
  if (props.isHomePage) {
    return props.url;
  }

  if (props.pageType === 'GP_STATIC') {
    return `${props.shopDomain}/pages/${handle.value}`;
  }

  if (props.pageType === 'GP_ARTICLE') {
    if (parseHandle.value.handleSplit.length > 1) {
      const handleName = handle.value.replace(`${parseHandle.value.patchName}/`, '');
      return `${props.shopDomain}/blogs/${parseHandle.value.patchName}/${handleName}/`;
    }

    return `${props.shopDomain}/blogs/news/${handle.value}/`;
  }

  return props.url + '/' + handle.value;
});

const isShowWarningAlert = computed(() => {
  return (
    props.pageType === 'GP_COLLECTION' ||
    props.pageType === 'GP_PRODUCT' ||
    props.pageType === 'GP_ARTICLE' ||
    props.pageType === 'GP_INDEX'
  );
});
</script>

<template>
  <form>
    <div>
      <div class="bg-light-100 flex w-[664px] flex-col rounded-xl p-16">
        <h1 class="text-14 text-light-high mb-8 font-medium leading-[24px]">Search Result Preview</h1>
        <template v-if="!isShowWarningAlert">
          <div class="bg-light-300 text-12 font-regular flex min-h-[64px] flex-col rounded-xl p-12 leading-[20px]">
            <div class="font-regular text-light-high max-w-[616px] overflow-hidden text-ellipsis">
              {{ title }}
            </div>
            <div class="font-regular text-primary-300 max-w-[616px] overflow-hidden text-ellipsis">
              <a :href="'https://' + pageUrl" target="_blank">{{ pageUrl }}</a>
            </div>
            <div class="font-regular text-light-high max-w-[616px] overflow-hidden text-ellipsis">
              {{ description }}
            </div>
          </div>
          <hr class="border-t-dark-200/10 my-16 border-t" />
          <div>
            <div class="mb-16">
              <div class="mb-8 flex items-center justify-between">
                <label class="text-12 text-light-high block font-medium leading-[20px]" for="title"
                  >SEO Page Title
                </label>
                <label
                  class="text-12 text-light-disabled font-regular block leading-[20px]"
                  for="title"
                  :class="{
                    ' text-red-300': errors?.title && errors?.title.length > 0,
                    'text-light-disabled': errors?.title && errors?.title.length === 0,
                  }">
                  {{ title?.length ?? 0 }}/70
                </label>
              </div>
              <input
                v-model="title"
                type="text"
                :class="{
                  ' border-red-300': errors?.title && errors?.title.length > 0,
                  'border-gray-3000': errors?.title && errors?.title.length === 0,
                }"
                placeholder="Title"
                class="text-14 bg-light-300 font-regular h-40 w-full appearance-none rounded-xl px-8 leading-tight text-gray-700 shadow focus:outline-none" />
              <div v-if="errors?.title" class="text-12 font-regular my-16 mt-8 flex gap-8 text-red-300">
                <g-base-icon name="status-error" viewBox="0 0 14 14" height="16px" width="16px"></g-base-icon>
                <p>{{ errors?.title }}</p>
              </div>
            </div>

            <div class="mb-16">
              <div class="mb-8">
                <div class="flex items-center justify-between">
                  <div class="flex items-center">
                    <label class="text-12 text-light-high mr-4 block font-medium leading-[20px]" for="title">
                      Page URL and Handle
                    </label>
                    <g-tooltip placement="top">
                      <span class="inline-flex text-gray-600">
                        <g-base-icon name="question" width="14" height="14" viewBox="0 0 14 14"></g-base-icon>
                      </span>
                      <template #content>
                        <span class="text-12">Page URL Address</span>
                      </template>
                    </g-tooltip>
                  </div>
                  <label
                    v-if="!isHomePage"
                    class="text-12 text-light-disabled font-regular block leading-[20px]"
                    :class="{
                      ' text-red-300': errors?.handle && errors?.handle.length > 0,
                      'text-light-disabled': errors?.handle && errors?.handle.length === 1,
                    }"
                    for="title">
                    {{ handle?.length ?? 0 }}/1000
                  </label>
                </div>
              </div>
              <div class="flex">
                <div
                  class="bg-light-500 text-14 font-regular text-light-disabled flex h-40 appearance-none items-center whitespace-nowrap rounded-l-xl border-r-0 px-8 leading-tight shadow"
                  :class="[isHomePage ? 'w-full' : 'w-fit max-w-[66%]']">
                  {{ wrapShopDomain }}
                </div>
                <div v-if="!isHomePage" class="w-full min-w-[34%]">
                  <input
                    :value="wrapHandle"
                    type="text"
                    placeholder="Handle"
                    :class="{
                      ' border-red-300': errors?.handle && errors?.handle.length > 0,
                      'border-gray-300': errors?.handle && errors?.handle.length === 1,
                    }"
                    class="text-14 bg-light-300 font-regular text-light-high h-40 w-full appearance-none rounded-r-xl px-8 leading-tight shadow focus:outline-none"
                    @input="handleChangeHandle" />
                  <div class="w-auto">
                    <div v-if="errors?.handle" class="text-12 font-regular my-16 mt-8 flex gap-8 text-red-300">
                      <g-base-icon name="status-error" viewBox="0 0 14 14" height="16px" width="16px"></g-base-icon>
                      <p>{{ errors?.handle }}</p>
                    </div>
                    <div
                      v-if="!validateHandle && errors?.handle"
                      class="text-12 font-regular my-16 mt-8 flex gap-8 text-red-300">
                      <g-base-icon name="status-error" viewBox="0 0 14 14" height="16px" width="16px"></g-base-icon>
                      <p>Page handle is already existed</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="mb-8 flex items-center justify-between">
                <label class="text-12 text-light-high block font-medium leading-[20px]" for="title">
                  SEO Description
                </label>
                <label
                  class="text-12 text-light-disabled font-regular block leading-[20px]"
                  for="title"
                  :class="{
                    ' text-red-300': errors?.description && errors?.description.length > 0,
                    'text-light-disabled': errors?.description && errors?.description.length === 1,
                  }">
                  {{ description?.length ?? 0 }}/320
                </label>
              </div>
              <textarea
                v-model="description"
                as="textarea"
                :class="{
                  ' border-red-300': errors?.description && errors?.description.length > 0,
                  'border-gray-300': errors?.description && errors?.description.length === 1,
                }"
                class="bg-light-300 text-14 font-regular text-light-high block min-h-[88px] w-full rounded-xl p-8 leading-tight outline-none" />
              <div v-if="errors?.description" class="text-12 font-regular my-16 mt-8 flex gap-8 text-red-300">
                <g-base-icon name="status-error" viewBox="0 0 14 14" height="16px" width="16px"></g-base-icon>
                <p>{{ errors?.description }}</p>
              </div>
            </div>
          </div>
        </template>
        <WarningAlert v-else type="SEO" :shop-domain="shopDomain" :page-type="pageType" />
      </div>
    </div>
  </form>
</template>
