<script lang="ts" setup>
import { FilterType } from '../types/tab';
import type { Tab } from '../types/tab';

const props = defineProps<{
  data: Tab;
  tabActive: FilterType;
}>();

const emit = defineEmits<{
  (e: 'change-tab', type: FilterType): void;
}>();

const customClass = (disabled: boolean, type: FilterType) => {
  let wrapper,
    content = '';
  if (props.tabActive == type) {
    wrapper = 'before:!bg-primary-300 before:!w-full';
    content = 'text-light-high !bg-primary-300/10 group-hover:bg-light-300';
  } else if (disabled) {
    wrapper = 'before:!w-0';
    content = 'text-light-disabled group-hover:!text-light-disabled !cursor-not-allowed';
  } else {
    content = 'text-light-low group-hover:bg-light-300';
  }
  return {
    wrapper,
    content,
  };
};
</script>
<template>
  <div class="flex items-center justify-center gap-4">
    <div
      v-for="(tab, type) in data"
      :key="type"
      class="gemx-library-tab group peer cursor-pointer"
      :class="[customClass(tab.disabled, type).wrapper, tab.disabled && '!cursor-not-allowed']"
      @click="!tab.disabled && emit('change-tab', type as FilterType)">
      <g-tooltip placement="bottom" :disabled="tabActive === type || !tab.disabled" :wrapper-class="'h-[40px]'">
        <div
          class="text-14 flex h-[40px] w-full cursor-pointer items-center justify-center rounded-t-xl px-16 font-medium"
          :class="customClass(tab.disabled, type).content">
          {{ tab.content }}
        </div>
        <template #content>
          <div class="text-12 text-[#F9F9F9]">This feature is unavailable in Theme Section</div>
        </template>
      </g-tooltip>
    </div>
  </div>
</template>
<style lang="postcss" scoped>
.gemx-library-tab {
  @apply relative flex h-[52px] w-auto min-w-[91px] items-end justify-center;
  @apply before:absolute before:bottom-[1px] before:h-[2px] before:w-0 before:bg-transparent before:transition-[width] before:duration-[250ms] hover:before:w-full;
}
</style>
