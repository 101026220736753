<script lang="ts" setup>
import { onUnmounted, ref, onMounted } from 'vue';
import GLoadingPlaceholder from '../base/GLoadingPlaceholder.vue';
import GButton from '../base/GButton.vue';
import GImage from '../base/GImage.vue';
let imageLoadingTimeout: NodeJS.Timeout | null;
const VELOCITY = 0.325;
const OFFSET_DISTANCE = 48;
const HEIGHT_DEFAULT = 220;
const MIN_HEIGHT = 120;
const SIDEBAR_WIDTH = 280;
const OFFSET_WIDTH = 88;
const MIN_WIDTH_FOR_3_COLUMNS = 1108;
const MODAL_WIDTH_PERCENT = 0.93334;
const XL_WIDTH = 1440;
const LG_WIDTH = 1024;
const MAX_HEIGHT_CONFIG = {
  xl: 432,
  lg: 285,
  default: 578,
};

const props = defineProps<{
  isLoading?: boolean;
  isPreview?: boolean;
  section: {
    name: string;
    thumbnail: {
      src: string;
      width: number;
      height: number;
    };
  };
  isSelectLoading?: boolean;
  isPreviewLoading?: boolean;
  thumbnailPreview?: string;
  imageLoaded?: () => void;
}>();

const getMaxHeight = () => {
  if (window.innerWidth >= XL_WIDTH) {
    return MAX_HEIGHT_CONFIG.xl;
  } else if (window.innerWidth >= LG_WIDTH) {
    return MAX_HEIGHT_CONFIG.lg;
  }
  return MAX_HEIGHT_CONFIG.default;
};

const getHeight = () => {
  const innerWidth = window.innerWidth;
  if (!props.section.thumbnail.width || !props.section.thumbnail.height) {
    return HEIGHT_DEFAULT;
  }
  const maxHeight = getMaxHeight();
  const ratio = props.section.thumbnail.width / props.section.thumbnail.height;
  if (window.innerWidth < MIN_WIDTH_FOR_3_COLUMNS) {
    return Math.min(
      Math.max((MODAL_WIDTH_PERCENT * innerWidth - SIDEBAR_WIDTH - OFFSET_WIDTH) / ratio, MIN_HEIGHT),
      maxHeight,
    );
  }
  return Math.min(
    Math.max((MODAL_WIDTH_PERCENT * innerWidth - SIDEBAR_WIDTH - OFFSET_WIDTH) / 3 / ratio, MIN_HEIGHT),
    maxHeight,
  );
};

const getOriginHeight = () => {
  const innerWidth = window.innerWidth;
  if (!props.section.thumbnail.width || !props.section.thumbnail.height) {
    return HEIGHT_DEFAULT;
  }
  const ratio = props.section.thumbnail.width / props.section.thumbnail.height;
  if (window.innerWidth < MIN_WIDTH_FOR_3_COLUMNS) {
    return (MODAL_WIDTH_PERCENT * innerWidth - SIDEBAR_WIDTH - OFFSET_WIDTH) / ratio;
  }
  return (MODAL_WIDTH_PERCENT * innerWidth - SIDEBAR_WIDTH - OFFSET_WIDTH) / 3 / ratio;
};

const thumbnailLoading = ref<boolean>(true);
const height = ref<number>(getHeight());
const originHeight = ref<number>(getOriginHeight());

const heightCalculator = {
  xl: 315,
  lg: 324,
  md: 324,
};

// Emit
const emit = defineEmits<{
  (e: 'select'): void;
  (e: 'preview'): void;
}>();

const handleSelect = () => {
  emit('select');
};
const handlePreview = () => {
  emit('preview');
};

const onThumbnailLoaded = () => {
  imageLoadingTimeout = setTimeout(() => {
    thumbnailLoading.value = false;
  }, 300);
};

const setHeight = () => {
  height.value = getHeight();
  originHeight.value = getOriginHeight();
};

onMounted(() => {
  window.addEventListener('resize', setHeight);
});

onUnmounted(() => {
  window.removeEventListener('resize', setHeight);
  if (imageLoadingTimeout) {
    clearTimeout(imageLoadingTimeout);
  }
});
</script>
<template>
  <div
    class="rounded-medium bg-light-100 z-5 group relative flex w-full flex-col overflow-hidden"
    :style="{
      height: `${height + 48}px`,
    }">
    <g-image
      :input-classes="`w-full min-h-[120px] z-[1] shrink grow max-h-[100%] ${isPreview ? 'cursor-pointer' : ''}`"
      width="100%"
      :wrapper-classes="'h-[calc(100%_-_48px)]'"
      :hidden-image-when-loading="true"
      :src="section.thumbnail.src"
      :origin-width="section.thumbnail.width"
      :origin-height="section.thumbnail.height"
      :height-calculator="heightCalculator"
      :height="`${originHeight}px`"
      :velocity="VELOCITY"
      :offset-distance="OFFSET_DISTANCE"
      @click.stop="handlePreview"
      @on-loaded="onThumbnailLoaded" />

    <div
      v-if="thumbnailLoading"
      class="bg-light-100 absolute top-0 left-0 z-50 flex h-full w-full flex-col justify-between">
      <g-loading-placeholder width="100%" height="100%" />
    </div>
    <div
      v-if="!isLoading"
      class="bg-light-200 z-5 group absolute bottom-0 left-0 flex h-[52px] w-full items-center gap-8 overflow-hidden border-t py-8 px-12 transition-all duration-200 group-hover:bottom-0">
      <div class="flex h-full w-[100%] items-center group-hover:hidden">
        <div
          class="text-light-high text-14 inline-block max-w-full truncate align-middle font-medium leading-6 leading-6">
          {{ section.name }}
        </div>
      </div>
      <g-button
        class="text-12 hidden h-[36px] w-[calc(50%_-_4px)] group-hover:flex"
        button-classes="justify-center w-full"
        type="primary"
        size="medium"
        :only-icon="true"
        :loading="isSelectLoading"
        @click.stop="handleSelect"
        >Add to page</g-button
      >
      <g-button
        class="text-12 hidden h-[36px] w-[calc(50%_-_4px)] group-hover:flex"
        :loading="isPreviewLoading"
        type="grey"
        size="medium"
        :only-icon="true"
        :disable="!isPreview"
        @click.stop="handlePreview">
        Preview
      </g-button>
    </div>
  </div>
</template>
