<script lang="ts" setup>
import GModal from '../../base/GModal.vue';
import GButton from '../../base/GButton.vue';

defineProps<{
  disabledViewLive?: any;
  isHasAnyShopifyApps?: boolean;
  labelHeader?: string;
  isShowTips?: boolean;
  labelTips?: string;
  linkTips?: string;
  classesTips?: string;
  hideHeaderIcon?: boolean;
  hideViewliveButton?: boolean;
}>();

const emit = defineEmits<{
  (e: 'view-live'): void;
  (e: 'close'): void;
  (e: 'view-tips'): void;
  (e: 'publish'): void;
}>();

const handleClose = () => {
  emit('close');
};

const handleViewLive = () => {
  emit('view-live');
};
const handleViewTips = () => {
  emit('view-tips');
};
</script>
<template>
  <g-modal
    :is-open="true"
    :hide-actions="true"
    :hide-header="true"
    :hide-overlay="true"
    :content-fixed="true"
    :click-out-side-close="true"
    modal-class="min-w-[360px]"
    container-class="top-[60px] w-[360px] right-10"
    @close="handleClose">
    <div class="bg-dark-500">
      <div class="flex items-center border-b border-[#333333] px-16 py-12">
        <span v-if="!hideHeaderIcon" class="mr-8"
          ><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 18.75C14.8325 18.75 18.75 14.8325 18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75ZM15.1893 7.69455C15.4348 7.45192 15.4372 7.0562 15.1945 6.81068C14.9519 6.56516 14.5562 6.56282 14.3107 6.80545L8.42558 12.6213L6.43933 10.6583C6.19381 10.4157 5.79809 10.418 5.55546 10.6635C5.31283 10.9091 5.31516 11.3048 5.56067 11.5474L7.98624 13.9445C8.2297 14.1852 8.62142 14.1852 8.86489 13.9445L15.1893 7.69455Z"
              fill="#00C853" />
          </svg>
        </span>
        <p class="text-dark-highV2 text-[16px] font-semibold leading-[24px]">
          {{ labelHeader ?? 'Publish successfully' }}
        </p>
        <span
          class="text-dark-low hover:text-dark-high ml-auto cursor-pointer rounded-xl transition-colors duration-200 hover:bg-[#3B3B3B]"
          @click="handleClose">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.6464 10.6464C10.8417 10.4512 11.1583 10.4512 11.3536 10.6464L21.3536 20.6464C21.5488 20.8417 21.5488 21.1583 21.3536 21.3536C21.1583 21.5488 20.8417 21.5488 20.6464 21.3536L10.6464 11.3536C10.4512 11.1583 10.4512 10.8417 10.6464 10.6464Z"
              fill="#E2E2E2" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M21.3536 10.6464C21.5488 10.8417 21.5488 11.1583 21.3536 11.3536L11.3536 21.3536C11.1583 21.5488 10.8417 21.5488 10.6464 21.3536C10.4512 21.1583 10.4512 20.8417 10.6464 20.6464L20.6464 10.6464C20.8417 10.4512 21.1583 10.4512 21.3536 10.6464Z"
              fill="#E2E2E2" />
          </svg>
        </span>
      </div>
      <slot name="body"></slot>
      <div class="p-16">
        <g-button
          v-if="!hideViewliveButton"
          data-test="editor-btn-after-publish"
          button-classes="w-full text-center justify-center"
          @click="handleViewLive">
          <template v-if="disabledViewLive">Preview template on shopify</template>
          <template v-else>View live page</template>
        </g-button>
        <slot name="button"></slot>
        <div v-if="isShowTips" class="rounded-medium mt-16 border border-[#333333] p-12" :class="classesTips">
          <div class="flex flex-col gap-8 pb-12">
            <div class="flex gap-8">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.99988 1.23901C10.3451 1.23901 10.6249 1.51884 10.6249 1.86401V3.61401C10.6249 3.95919 10.3451 4.23901 9.99988 4.23901C9.6547 4.23901 9.37488 3.95919 9.37488 3.61401V1.86401C9.37488 1.51884 9.6547 1.23901 9.99988 1.23901Z"
                  fill="#AAAAAA" />
                <path
                  d="M7.49988 16.875C7.49988 16.5298 7.7797 16.25 8.12488 16.25H11.8749C12.2201 16.25 12.4999 16.5298 12.4999 16.875C12.4999 17.2202 12.2201 17.5 11.8749 17.5H8.12488C7.7797 17.5 7.49988 17.2202 7.49988 16.875Z"
                  fill="#AAAAAA" />
                <path
                  d="M1.96338 8.90076C1.6182 8.90076 1.33838 9.18058 1.33838 9.52576C1.33838 9.87093 1.6182 10.1508 1.96338 10.1508H3.96338C4.30856 10.1508 4.58838 9.87093 4.58838 9.52576C4.58838 9.18058 4.30856 8.90076 3.96338 8.90076H1.96338Z"
                  fill="#AAAAAA" />
                <path
                  d="M15.4111 9.52576C15.4111 9.18058 15.6909 8.90076 16.0361 8.90076H18.0361C18.3813 8.90076 18.6611 9.18058 18.6611 9.52576C18.6611 9.87093 18.3813 10.1508 18.0361 10.1508H16.0361C15.6909 10.1508 15.4111 9.87093 15.4111 9.52576Z"
                  fill="#AAAAAA" />
                <path
                  d="M14.3415 5.2507C14.0975 5.49478 14.0975 5.89051 14.3415 6.13459C14.5856 6.37867 14.9813 6.37867 15.2254 6.13459L16.4628 4.89715C16.7069 4.65307 16.7069 4.25735 16.4628 4.01327C16.2188 3.76919 15.823 3.76919 15.579 4.01327L14.3415 5.2507Z"
                  fill="#AAAAAA" />
                <path
                  d="M5.65753 6.13433C5.90161 5.89026 5.90161 5.49453 5.65753 5.25045L4.42009 4.01301C4.17601 3.76894 3.78029 3.76894 3.53621 4.01301C3.29213 4.25709 3.29213 4.65282 3.53621 4.8969L4.77364 6.13433C5.01772 6.37841 5.41345 6.37841 5.65753 6.13433Z"
                  fill="#AAAAAA" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.99976 5.17151C7.51447 5.17151 5.49976 7.18623 5.49976 9.67151C5.49976 11.1779 6.24025 12.5108 7.37476 13.3269V15.0125C7.37476 15.3577 7.65458 15.6375 7.99976 15.6375H11.9998C12.3449 15.6375 12.6248 15.3577 12.6248 15.0125V13.3269C13.7593 12.5108 14.4998 11.1779 14.4998 9.67151C14.4998 7.18623 12.485 5.17151 9.99976 5.17151ZM6.74976 9.67151C6.74976 7.87658 8.20483 6.42151 9.99976 6.42151C11.7947 6.42151 13.2498 7.87658 13.2498 9.67151C13.2498 10.852 12.6208 11.8861 11.6767 12.4561C11.4893 12.5693 11.3748 12.7722 11.3748 12.9912V14.3875H8.62476V12.9912C8.62476 12.7722 8.51021 12.5693 8.3228 12.4561C7.37872 11.8861 6.74976 10.852 6.74976 9.67151Z"
                  fill="#AAAAAA" />
              </svg>
              <div class="font-regular text-[12px] leading-[20px] text-[#F9F9F9]">
                {{ labelTips }}
              </div>
            </div>
          </div>
          <div class="text-primary-200 hover:text-primary-150 ml-[28px] text-[12px] font-medium leading-[20px]">
            <div class="flex w-fit cursor-pointer items-center" @click="handleViewTips">
              <span class="mr-4">{{ linkTips }}</span>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.5 4C4.22386 4 4 4.22386 4 4.5V11.502C4 11.7781 4.22386 12.002 4.5 12.002H11.5C11.7761 12.002 12 11.7781 12 11.502V9.38589C12 9.10975 12.2239 8.88589 12.5 8.88589C12.7761 8.88589 13 9.10975 13 9.38589V11.502C13 12.3304 12.3284 13.002 11.5 13.002H4.5C3.67157 13.002 3 12.3304 3 11.502V4.5C3 3.67157 3.67157 3 4.5 3H6.61539C6.89153 3 7.11539 3.22386 7.11539 3.5C7.11539 3.77614 6.89153 4 6.61539 4H4.5Z"
                  fill="#8AA4FF" />
                <path
                  d="M12 4.70711V7.00024C12 7.27639 12.2239 7.50024 12.5 7.50024C12.7761 7.50024 13 7.27639 13 7.00024V3.50024C13 3.2241 12.7761 3.00024 12.5 3.00024H9C8.72386 3.00024 8.5 3.2241 8.5 3.50024C8.5 3.77639 8.72386 4.00024 9 4.00024H11.2926L6.01597 9.27692C5.82071 9.47218 5.82071 9.78876 6.01597 9.98403C6.21124 10.1793 6.52782 10.1793 6.72308 9.98403L12 4.70711Z"
                  fill="#8AA4FF" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </g-modal>
</template>
