<template>
  <div class="flex h-full items-end justify-center gap-4">
    <div
      v-for="tab in tabs"
      :key="tab.id"
      data-test="editor-tab"
      class="group flex h-full cursor-pointer items-end justify-center gap-4"
      @click="setActiveTabId(tab.id)">
      <div
        class="group-hover:bg-light-300 flex h-[40px] cursor-pointer items-center justify-center rounded-t-xl border-b-[2px] border-solid border-transparent px-16 py-8 transition-colors duration-200"
        :class="{
          'text-light-low hover:text-light-high border-b-[1px]  border-solid border-transparent':
            tab.id !== activeTabId,
          'border-primary-300 text-light-high !bg-primary-300/10  ': tab.id === activeTabId,
        }">
        <p class="text-14 font-medium">
          {{ tab.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  tabs: Array<{
    id: string;
    name: string;
  }>;
  activeTabId: string;
}>();

const emit = defineEmits<{
  (e: 'change-tab', id: string): void;
}>();

const setActiveTabId = (id: string) => {
  emit('change-tab', id);
};
</script>
