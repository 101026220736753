<script lang="ts" setup>
import GBaseIcon from '../../base/GBaseIcon.vue';
import GMenuItem from '../../elements/MenuItem.vue';
import GButton from '../../base/GButton.vue';
import { computed, ref, watch } from 'vue';
import type { DomainItem } from '../../types/domain';
import DnsInfo from './DNSInfo.vue';

type Props = {
  domain: DomainItem;
  primaryDomain?: string;
  defaultDomain?: string;
  defaultDns: string;
  ipAddress: string;
  makingPrimaryDomain?: DomainItem;
  isMisconfig?: boolean;
  isRemoving?: boolean;
  isCheckingSatisfied?: boolean;
  isMakingPrimary?: boolean;
  isEnabledViewLive?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'remove-domain'): void;
  (e: 'set-primary-domain'): void;
  (e: 'reverify-domain'): void;
  (e: 'open-domain'): void;
}>();
const enableTrashMode = ref<boolean>(false);
const EXIST_PROJECT_DOMAIN_ERROR = 'existing_project_domain';

const primaryDomainIcon = computed(() => (isDomainSatisfied.value ? 'normal-domain-fill' : 'error'));

const isDomainSatisfied = computed<boolean>(() => !props.isMisconfig && !!props.domain?.isVerified);

const isMakingOtherDomainPrimary = computed<boolean>(
  () => !!props.makingPrimaryDomain && props.domain.id !== props.makingPrimaryDomain.id,
);

const isLoadingButtons = computed<boolean>(
  () => !!props.isCheckingSatisfied || !!props.isRemoving || !!props.isMakingPrimary,
);

const isErrorExistProjectDomain = computed<boolean>(() => {
  if (!props.domain.verification?.length) {
    return false;
  }
  const error = props.domain.verification[0];
  return error?.reason === EXIST_PROJECT_DOMAIN_ERROR;
});

const setAsPrimary = () => {
  emit('set-primary-domain');
};

const reverifyDomain = () => {
  emit('reverify-domain');
};

const removeDomain = () => {
  emit('remove-domain');
};

const openDomain = () => {
  if (props.isCheckingSatisfied) {
    return;
  }
  if (props.domain.isPrimary || isDomainSatisfied.value) {
    emit('open-domain');
  }
};

watch(
  () => props.isRemoving,
  () => {
    if (!props.isRemoving) {
      enableTrashMode.value = false;
    }
  },
);
</script>

<template>
  <g-menu-item :menu-item-style="'large'" :enable-content="true"
    ><template #icon
      ><div :key="primaryDomainIcon">
        <span
          :class="{
            'text-red-300': !isDomainSatisfied,
            '!text-primary-300': isDomainSatisfied && domain.isPrimary,
            '!text-light-low': isCheckingSatisfied,
          }">
          <g-base-icon :name="primaryDomainIcon" width="16" height="16" view-box="0 0 16 16"></g-base-icon
        ></span></div
    ></template>
    <template #text>
      <div class="flex items-end">
        <div class="max-w-[350px] truncate">
          <span
            :class="{
              'text-primary-300 cursor-pointer': isDomainSatisfied && !isCheckingSatisfied,
              'text-red-300': domain.isPrimary && !isDomainSatisfied && !isCheckingSatisfied,
              'text-light-low cursor-default': isCheckingSatisfied || (!domain.isPrimary && !isDomainSatisfied),
            }"
            @click="openDomain"
            >{{ domain?.domain }}</span
          >
        </div>
        <span
          v-if="domain?.isPrimary"
          class="text-12 rounded-medium ml-10 px-4 py-[1px] text-white"
          :class="{
            'bg-primary-300': isDomainSatisfied && !isCheckingSatisfied,
            'bg-red-300': !isDomainSatisfied && !isCheckingSatisfied,
            'bg-light-low': isCheckingSatisfied,
          }"
          >Primary</span
        >
      </div>
    </template>
    <template #description>
      <span class="dark" :class="{ 'text-red-300': !isDomainSatisfied, 'text-light-low': isCheckingSatisfied }">
        <template v-if="!domain.isVerified">
          <span v-if="isErrorExistProjectDomain"> This domain is already used for another store </span>
          <span v-else> The DNS Record is missing, let create new one using below values </span>
        </template>
        <template v-else-if="isMisconfig"> The DNS Record is not matched, please recheck and update it </template>
        <template v-else>
          {{ domain.isPrimary ? 'All instant pages will be directed to this domain' : `Redirect to ${primaryDomain}` }}
        </template>
      </span>
    </template>
    <template #action>
      <div v-if="domain?.isPrimary && isDomainSatisfied" class="flex flex-row items-center">
        <g-button
          v-if="!isCheckingSatisfied"
          type="ghost"
          size="medium"
          :only-icon="true"
          :disable="!isEnabledViewLive"
          @click="openDomain">
          <span :class="{ 'text-light-high': isEnabledViewLive, 'text-light-disabled': !isEnabledViewLive }">
            <g-base-icon name="open-new-tab" width="16" height="16" view-box="0 0 16 16"></g-base-icon>
          </span>
        </g-button>
        <g-button
          v-else
          type="invert"
          button-classes="!bg-white hover:!bg-dark-high !px-8"
          :disable="true"
          :loading="true"
          size="medium">
          <span class="text-12 font-medium">Verifying</span>
        </g-button>
      </div>
      <div v-else-if="!enableTrashMode" class="flex flex-row items-center gap-8">
        <g-button
          v-if="isCheckingSatisfied"
          type="invert"
          button-classes="!bg-white hover:!bg-dark-high !px-8"
          :disable="true"
          :loading="true"
          size="medium">
          <span class="text-12 font-medium">Verifying</span>
        </g-button>
        <g-button
          v-else-if="isDomainSatisfied"
          type="invert"
          button-classes="  !px-8"
          :disable="isLoadingButtons || isMakingOtherDomainPrimary"
          :loading="isLoadingButtons"
          size="medium"
          @click="setAsPrimary">
          <span class="text-12 font-medium">Make Primary</span>
        </g-button>
        <g-button
          v-else
          type="invert"
          button-classes="!bg-white hover:!bg-dark-high !px-8"
          size="medium"
          @click="reverifyDomain">
          <span class="text-12 font-medium">Reverify</span>
        </g-button>
        <g-button
          v-if="!domain.isPrimary"
          :disable="isLoadingButtons || domain.domain === defaultDomain"
          type="invert"
          size="medium"
          button-classes="!bg-white hover:!bg-dark-high !h-32 !w-32 !p-0 justify-center"
          @click="!isLoadingButtons ? (enableTrashMode = true) : ''">
          <span class="gap-8">
            <g-base-icon name="trash" width="16" height="16" view-box="0 0 16 16"></g-base-icon>
          </span>
        </g-button>
      </div>
      <div v-if="!!enableTrashMode && domain.domain !== defaultDomain" class="flex flex-row items-center gap-8">
        <g-button
          v-if="!isLoadingButtons"
          type="invert"
          button-classes="!bg-white hover:!bg-dark-high !px-8"
          size="medium"
          :disable="isLoadingButtons"
          @click="enableTrashMode = false">
          <span class="text-12"> Cancel </span>
        </g-button>
        <g-button
          type="danger"
          size="medium"
          button-classes="!px-8"
          :loading="isLoadingButtons"
          :disable="isLoadingButtons"
          @click="removeDomain">
          <span class="text-12"> Delete </span>
        </g-button>
      </div>
    </template>
    <template #content>
      <div v-if="!domain.isVerified && !isErrorExistProjectDomain">
        <dns-info
          :domain="domain.domain || ''"
          :error-verification="domain.verification"
          :default-dns="defaultDns"
          :ip-address="ipAddress"></dns-info>
      </div>
      <div v-else-if="isMisconfig && !isErrorExistProjectDomain">
        <dns-info :domain="domain.domain || ''" :default-dns="defaultDns" :ip-address="ipAddress"></dns-info>
      </div>
    </template>
  </g-menu-item>
</template>
