<script lang="ts" setup>
import { ref, watch } from 'vue';
import { GChip } from '@gem/uikit';

type CheckBoxData = {
  id?: string;
  value?: boolean;
  name?: string;
  countItem?: number;
  isParent?: boolean;
  isCheckedAll?: boolean;
  parentId?: string;
  isUpComing?: boolean;
};

type MultiCheckBoxProps = {
  data: CheckBoxData[];
  defaultValue?: string[];
  classes?: string;
  isUpComing?: boolean;
  upcomingDesc: string;
  wrapperClasses?: string;
};

const emit = defineEmits<{
  (e: 'clear'): void;
  (e: 'change', ids?: string[]): void;
  (e: 'clickItem', id: string, title: string): void;
}>();

const props = withDefaults(defineProps<MultiCheckBoxProps>(), {
  data: () => [],
});

const dataSrc = ref(props.data);

const change = ({
  index,
  value,
  isUpComing,
  isParent,
}: {
  index: number;
  value: boolean;
  isUpComing?: boolean;
  isParent?: boolean;
}) => {
  if (isUpComing) {
    return;
  }

  if (dataSrc.value?.[index]) {
    if (value) {
      emit('clickItem', dataSrc.value?.[index]?.id || '', dataSrc.value?.[index]?.name || '');
    }
    dataSrc.value[index].value = value;
    if (isParent) {
      const parentID = dataSrc.value?.[index]?.id;

      const cloneData = dataSrc.value.map((e) => {
        if (e.parentId === parentID) {
          e.value = value;
        }
        return e;
      });
      dataSrc.value = cloneData;
    } else {
      const parentID = dataSrc.value?.[index]?.parentId;
      const parentIndex = dataSrc.value?.findIndex((e) => e?.id === parentID);
      const child = dataSrc.value?.filter((e) => e?.parentId === parentID);

      if (dataSrc.value?.[parentIndex]) {
        if (child.some((e) => e?.value === true)) {
          dataSrc.value[parentIndex].value = true;
        } else dataSrc.value[parentIndex].value = false;

        if (child.every((e) => e?.value === true)) {
          dataSrc.value[parentIndex].isCheckedAll = true;
        } else dataSrc.value[parentIndex].isCheckedAll = false;
      }
    }
  }

  const categoriesChecked = dataSrc.value?.filter((e) => e?.value === true);
  const filterUseCaseIds = categoriesChecked?.map((e) => e?.id ?? '');
  emit('change', filterUseCaseIds);
};

watch(
  () => props.data,
  (v) => {
    dataSrc.value = v;
  },
);

const checkMinus = (parentID?: string) => {
  const children = dataSrc.value?.filter((e) => e.parentId === parentID);
  return children?.every((d) => d.value === true);
};
</script>
<template>
  <div class="w-full px-8 pt-16">
    <slot name="title"></slot>
    <div v-if="isUpComing" class="text-light-low text-12 font-regular leading-[150%]">
      {{
        upcomingDesc ||
        'The suggested use cases will help you choose a suitable template for your sales campaign easier'
      }}
    </div>
    <label
      v-for="(item, index) in dataSrc"
      v-else
      :key="index"
      :for="`multi-checkbox-value-${index}`"
      class="hover:bg-light-250 flex w-full cursor-pointer select-none items-center rounded-xl p-8 align-baseline"
      :class="{ 'hover:bg-light-250 cursor-pointer': !item.isUpComing, 'cursor-not-allowed': item.isUpComing }">
      <div
        :class="[
          'text-14 relative ml-0 flex items-center leading-6',
          item.isParent ? 'text-light-high' : 'text-light-low font-regular',
        ]">
        <div class="mr-8 flex h-24 w-20 items-center justify-center">
          <input
            :id="`multi-checkbox-value-${index}`"
            class="gemx-checkbox peer h-[16px] w-[16px]"
            type="checkbox"
            :disabled="item.isUpComing"
            :name="item.name"
            :value="item.value"
            :checked="item.value"
            @change="
              change({
                index: index,
                value: ($event.target as HTMLInputElement).checked,
                isParent: item.isParent,
                isUpComing: item.isUpComing,
              })
            " />
          <span
            :class="{
              upcoming: item.isUpComing,
            }"
            class="gemx-checkmark peer-checked:bg-primary-300 peer-disabled:border-light-500 flex items-center justify-center peer-checked:border-none [&>svg]:hidden peer-checked:[&>svg]:block">
            <svg
              v-if="item?.isParent && !checkMinus(item.id)"
              width="10"
              height="4"
              viewBox="0 0 10 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="0.625" y="0.90625" width="8.75" height="2.1875" rx="1.09375" fill="white" />
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8415 5.1766C16.0528 5.41207 16.0528 5.79383 15.8415 6.0293L7.95016 14.8234C7.84869 14.9365 7.71107 15 7.56757 15C7.42408 15 7.28645 14.9365 7.18499 14.8234L4.15846 11.4505C3.94717 11.215 3.94718 10.8333 4.15848 10.5978C4.36978 10.3623 4.71236 10.3623 4.92365 10.5978L7.56759 13.5443L15.0764 5.1766C15.2877 4.94113 15.6302 4.94113 15.8415 5.1766Z"
                fill="#F9F9F9"
                stroke="#F9F9F9"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
        </div>
        <div
          class="text-14 font-regular text-light-high mr-8 select-none truncate whitespace-normal leading-6"
          :class="{ 'text-light-disabled': item.isUpComing }">
          {{ item.name }}
        </div>
      </div>
      <div v-if="item.countItem && !item.isUpComing" :class="['text-light-low text-14 ml-auto leading-6']">
        {{ item.countItem }}
      </div>
      <g-chip v-else-if="item.isUpComing" type="informative" mode="light" class="ml-auto">Upcoming</g-chip>
    </label>
  </div>
</template>

<style scoped lang="scss">
.gemx-checkbox {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.gemx-checkmark {
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1.25px solid #676767;
  border-radius: 3px;
}

.upcoming {
  border: 1.25px solid #d6d6d6;
}
.gemx-checkbox:checked {
  background: white;
}
</style>
