<template>
  <rect width="66" height="48" rx="3" fill="#212121" />
  <path d="M9 27H57V35C57 36.6569 55.6569 38 54 38H12C10.3431 38 9 36.6569 9 35V27Z" fill="#5E5E5E" />
  <path
    d="M13 32.5C13 31.6716 13.6716 31 14.5 31H39.5C40.3284 31 41 31.6716 41 32.5C41 33.3284 40.3284 34 39.5 34H14.5C13.6716 34 13 33.3284 13 32.5Z"
    fill="#757575" />
  <path
    d="M43 32.5C43 31.6716 43.6716 31 44.5 31H51.5C52.3284 31 53 31.6716 53 32.5C53 33.3284 52.3284 34 51.5 34H44.5C43.6716 34 43 33.3284 43 32.5Z"
    fill="#757575" />
  <rect x="9.5" y="10.5" width="47" height="27" rx="2.5" stroke="#474747" />
</template>
